import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateService } from '@tolgee/ngx';
import moment from 'moment';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-lang-selector',
  template: `
    <select
      (change)="changeLanguage($event)"
      [value]="translateService.languageAsync | async"
      class="appearance-none text-gray-700 border border-gray-200 rounded-lg py-2 px-2 pr-6 leading-tight
      focus:outline-none focus:bg-white focus:border-gray-500">
      <option value="fr">🇫🇷&nbsp;</option>
      <option value="en">🇬🇧&nbsp;</option>
      <!--<option value="de">🇩🇪&nbsp;</option>-->
    </select>
  `,
  imports: [
    AsyncPipe,
    ReactiveFormsModule
  ]
})
export class LangSelectorComponent {
  translateService = inject(TranslateService);

  changeLanguage(event: Event): Promise<void> {
    const target: HTMLInputElement = event.target as HTMLInputElement;
    if (!target) {
      return Promise.resolve();
    }
    moment.locale(target.value);
    return this.translateService.changeLanguage(target.value)
  }
}
