import { NgClass, NgOptimizedImage, } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, inject, } from '@angular/core';
import { RouterLink } from '@angular/router';
import { NgxTolgeeModule } from '@tolgee/ngx';

import { SignInComponent } from '../account/sign-in/sign-in.component';
import { emulateClick } from '../core/a11y';
import { DrawerService } from '../drawer/drawer.service';
import { SocialsComponent } from '../shared/components/socials/socials.component';
import { LangSelectorComponent } from '../shared/lang-selector/lang-selector.component';
import { AuthService } from '../shared/services/auth.service';
import { CenterService } from '../shared/services/center.service';
import { NavigationService } from '../shared/services/navigation.service';
import { PageService } from '../shared/services/page.service';
import { SiteService } from '../shared/services/site.service';
import { UniverseService } from '../shared/services/universe.service';

type Column = 'centers' | 'activities' | 'customer' | 'les100ciels' | 'offer'

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-footer',
  imports: [NgxTolgeeModule, NgOptimizedImage, NgClass, RouterLink, SocialsComponent, LangSelectorComponent],
  templateUrl: './footer.component.html'
})
/**
 * Display the footer
 */
export class FooterComponent {
  authService = inject(AuthService);
  centerService = inject(CenterService);
  siteService = inject(SiteService);
  drawerService = inject(DrawerService);
  universeService = inject(UniverseService);
  pageService = inject(PageService);
  navigationService = inject(NavigationService);
  readonly emulateClick = emulateClick;
  currentYear = ((): number => new Date().getFullYear())();
  opened: Column | null = null;
  displayedPages = computed(() => {
    const center = this.siteService.center();
    const pages = this.pageService.pages();
    return pages.filter(page =>
      page.centerContent.find(cc => cc.center === center?.id)?.content || page.content)
  });
  protected readonly SignInComponent = SignInComponent;

  open(column: Column): void {
    this.opened = this.opened === column ? null : column;
  }
}
