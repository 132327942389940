import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, inject, } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  ReactiveFormsModule,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { RouterLink } from '@angular/router';
import { NgxTolgeeModule } from '@tolgee/ngx';

import { Roles } from '../../../../../core';
import { emulateClick } from '../../core/a11y';
import { NotificationService } from '../../notification/notification.service';
import { HoverGradientDirective } from '../../shared/directives/hover-gradient.directive';
import { AuthService } from '../../shared/services/auth.service';
import { EmailValidator } from '../../shared/validators/validators';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-profile-card',
  imports: [CommonModule, NgxTolgeeModule, ReactiveFormsModule, HoverGradientDirective, RouterLink],
  templateUrl: './profile-card.component.html'
  //template: `
  //  <div class="bg-white">
  //    <h1>{{ 'auth.profile' | translate }}</h1>
  //    <section>
  //      <button class="btn" (click)="signOut()">{{ 'auth.signOut' | translate }}</button>
  //      <button class="btn" (click)="updateRoles()">{{ 'auth.toggleRoleOther' | translate }}</button>
  //    </section>
  //    <section>
  //      <input type="email" [formControl]="newEmail">
  //      <button class="btn" [disabled]="newEmail.invalid"
  //              (click)="updateEmail()">{{ 'auth.updateEmail' | translate }}
  //      </button>
  //    </section>
  //  </div>
  //`,
})
/**
 * Profile component
 */
export class ProfileCardComponent {
  readonly emulateClick = emulateClick;
  authService = inject(AuthService);
  newEmail = new FormControl('', {
    nonNullable: true,
    validators: [Validators.required, EmailValidator, this.differentFromCurrent.apply(this)],
  });
  displayName = computed(() => {
    const user = this.authService.user();
    return user?.firstName ?? user?.email
  });
  private notification = inject(NotificationService);

  /**
   * Sign out the user
   * @returns The promise
   */
  async signOut(): Promise<void> {
    try {
      await this.authService.signOut();
    } catch (err) {
      this.notification.open({ type: 'error', title: 'error', message: (err as Error).message });
    }
  }

  /**
   * Update the current user roles
   */
  async updateRoles(): Promise<void> {
    const { uid, roles } = this.authService.user()!;
    const newRoles = roles.includes(Roles.OtherRead) ?
      roles.filter(r => r !== Roles.OtherRead) :
      [...roles, Roles.OtherRead];
    try {
      await this.authService.updateRoles(uid, newRoles);
      await this.authService.getIdToken(true);
    } catch (err) {
      this.notification.open({ type: 'error', title: 'error', message: (err as Error).message });
    }
  }

  /**
   * Update the user email
   * @returns The promise
   */
  async updateEmail(): Promise<void> {
    try {
      await this.authService.updateEmail(this.newEmail.value);
    } catch (err) {
      this.notification.open({ type: 'error', title: 'error', message: (err as Error).message });
    }
  }

  /**
   * Check if new email is different from current user email
   * @returns sameEmail is they do match
   */
  private differentFromCurrent(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors => control.value === this.authService.user()!.email ?
      { sameEmail: true } :
      {};
  }
}
