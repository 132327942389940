import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CouponCartItem } from '../../../../core/model/cart/cart-item.model';
import { CartItemDetailComponent } from '../checkout/cart-item-detail/cart-item-detail.component';
import { NgxTolgeeModule } from '@tolgee/ngx';
import { emulateClick } from '../core/a11y';
import { DrawerService } from '../drawer/drawer.service';
import { NotificationService } from '../notification/notification.service';
import { HoverGradientDirective } from '../shared/directives/hover-gradient.directive';
import { ProgressClickDirective } from '../shared/directives/progress-click.directive';
import { CartService } from '../shared/services/cart.service';
import { CenterService } from '../shared/services/center.service';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-claim-gift',
  imports: [
    HoverGradientDirective,
    NgxTolgeeModule,
    FormsModule,
    ReactiveFormsModule,
    ProgressClickDirective,
    CartItemDetailComponent
  ],
  templateUrl: './claim-gift.component.html'
})
export class ClaimGiftComponent {
  readonly emulateClick = emulateClick;
  centerService = inject(CenterService);
  drawerService = inject(DrawerService);
  notificationService = inject(NotificationService);
  code = new FormControl('', { nonNullable: true, validators: [Validators.required] });
  couponCartItem: CouponCartItem | null = null;
  nothingToDo: boolean = false;
  private cartService = inject(CartService);
  private router = inject(Router);

  /**
   * Check the validity and apply a voucher on user cart
   */
  addVoucher = async (): Promise<void> => {
    this.couponCartItem = null;
    this.nothingToDo = false;
    const couponCartItem = await this.cartService.addGiftVoucher(this.code.value);
    if (!couponCartItem) {
      this.notificationService.open({
        type: 'error',
        title: 'error',
        message: 'invalidVoucherCode'
      });
      return;
    }
    this.couponCartItem = couponCartItem;
    this.nothingToDo = this.couponCartItem?.consumables
      .every(c => c.items
        .every(element => element.type === 'anonymous'))
    return;
  }
  addToCart = async (): Promise<void> => {
    const cartItem = this.couponCartItem;
    if (!cartItem) {
      return;
    }
    await this.cartService.add([{ cartItem }]);
    this.code.setValue('');
    this.couponCartItem = null;
    this.nothingToDo = false;
    await this.router.navigate(['/checkout'])
  };
}
